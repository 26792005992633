import React, { Suspense } from "react";
import "./App.scss";
import { Route, Routes } from "react-router-dom";
import { Home } from "./pages/home";
import { Myself } from "./pages/myself";
import { VisaType } from "./pages/visaType";
import { ContainerLayout } from "./components/container";
import { PremiumProcessing } from "./pages/premiumProcessing";
import { Final } from "./pages/final";
import { ExistingAccount } from "./pages/existingAccount";
import { PetitionerForm } from "./pages/petitioner";
import "react-phone-input-2/lib/style.css";
import { LandingPage } from "./pages/LandingPage";
import { MyContext } from "./MyContext";

function App() {
  const [firmunqiue, setFirmUnique] = React.useState("");
  const [firmDetails, setFirmDetails] = React.useState("");
  return (
    <div className="App">
      <MyContext.Provider
        value={{ firmunqiue, setFirmUnique, firmDetails, setFirmDetails }}
      >
        <header className="App-header">
          <Suspense fallback={<div>loading...</div>}>
            <Routes>
              <Route element={<ContainerLayout />}>
                <Route path="/home" element={<Home />} />
                <Route path="/account" element={<ExistingAccount />} />
                <Route path="/myself" element={<Myself />} />
                <Route path="/petitioner" element={<PetitionerForm />} />
                <Route path="/visatype" element={<VisaType />} />
                <Route path="/premiumProcess" element={<PremiumProcessing />} />
                <Route path="/final-success" element={<Final />} />
                <Route path="/:id" element={<LandingPage />} />
                {/* <Route path="*" element={<Navigate to={"/account"} />} /> */}
              </Route>
            </Routes>
          </Suspense>
        </header>
      </MyContext.Provider>
    </div>
  );
}

export default App;
