import React, { useContext, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInput from "../../components/customInput";
import { visaTypeSchema } from "../../utils/validatorSchema";
import "./index.scss";
import { Header } from "../../components/header";
import { Footer } from "../../components/footer";
import CustomSelect from "../../components/customSelect";
import { visaTypeList } from "../../utils/constant";
import { useNavigate } from "react-router-dom";
import {
  apiCall,
  getFormVales,
  resetFormValues,
  setBodyValues,
} from "../../utils/store";
import { MyContext } from "../../MyContext";

export const VisaType = () => {
  const navigate = useNavigate();
  const { firmunqiue } = useContext<any>(MyContext);
  const [loader, setLoader] = React.useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    trigger,
  } = useForm<any>({
    criteriaMode: "all",
    defaultValues: {
      visaType: "",
      notes: "",
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(visaTypeSchema as any),
  });
  const sumitForm = async (formValue: any) => {
    try {
      const visaObj = {
        visaType: formValue.visaType ? [formValue.visaType] : [],
        leadNotes: formValue.notes ? formValue.notes : "",
      };
      setBodyValues(visaObj);
      // eslint-disable-next-line eqeqeq
      if (watch("visaType") == 1) {
        navigate("/premiumProcess");
        return;
      }
      setLoader(true);
      await apiCall(firmunqiue);
      navigate("/final-success");
      resetFormValues();
      setLoader(false);
    } catch (error) {
      if (error === "This Petitioner already accepted your Invite") {
        navigate(
          "/final-success?error=This Petitioner already accepted your Invite"
        );
      }
      setLoader(false);
    }
  };
  useEffect(() => {
    const savedObj: any = getFormVales();
    if (
      (savedObj?.visaType && savedObj?.visaType.length) ||
      savedObj?.leadNotes
    ) {
      setValue(
        "visaType",
        savedObj?.visaType && savedObj?.visaType[0]
          ? savedObj?.visaType[0]
          : null
      );
      setValue("notes", savedObj.leadNotes ? savedObj.leadNotes : "");
      trigger();
    }
  }, [setValue, trigger]);
  return (
    <React.Fragment>
      {loader && <div className="liberty-loader" />}
      <Header
        chatTitle="Please share more about your situation, your immigration goals, and
whether you think you are a beneficiary (applicant) or petitioner
(sponsor)."
      />
      <div className="visa_container">
        <div className="header">My Visa Type</div>
        <form onSubmit={handleSubmit(sumitForm)}>
          <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid item xs={12} md={12} sm={12}>
              <Controller
                control={control}
                defaultValue={""}
                name={"visaType"}
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <CustomSelect
                      labelKey={"visa_code"}
                      valueKey={"visa_id"}
                      options={
                        visaTypeList && visaTypeList.data.length
                          ? visaTypeList.data
                          : []
                      }
                      name={name}
                      value={value}
                      onChange={(val) => onChange(val)}
                      placeHolder={"Select"}
                      className={"custom-input"}
                      error={errors.visaType?.message}
                      searchable={true}
                      tabIndex={2}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <Typography component={"div"} className="custom-label">
                Notes
              </Typography>
              <Controller
                control={control}
                defaultValue={""}
                name={"notes"}
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <CustomInput
                      placeHolder={"Notes"}
                      name={name}
                      value={value}
                      className={"custom-textarea"}
                      error={errors.notes?.message || ""}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      isTextArea={true}
                      minRows={5}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </form>
      </div>
      <Footer
        submit={() => handleSubmit(sumitForm)()}
        // eslint-disable-next-line eqeqeq
        submitbtn={watch("visaType") == 1 ? false : true}
      />
    </React.Fragment>
  );
};
