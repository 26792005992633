import React from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { Header } from "../../components/header";
import { setBodyValues } from "../../utils/store";

export const Home = () => {
  const navigate = useNavigate();
  const setFormObjResgisterType = (type: string) => {
    setBodyValues({ applyingFor: type });
  };
  const [chooseOptions, setChooseOptions] = React.useState({
    show: false,
    type: "",
  });
  return (
    <React.Fragment>
      <Header
        chatTitle="Please share some details to
help us get to know you."
      />
      <div className="main_content">
        <div className="heading">
          Please select an appropriate option that describes your immigration
          needs
        </div>
        {!chooseOptions.show ? (
          <>
            <div
              className="btn-content"
              onClick={() => {
                setChooseOptions((pre) => ({
                  ...pre,
                  show: true,
                  type: "employee",
                }));
              }}
            >
              Employment Based
            </div>

            <div
              className="btn-content"
              onClick={() => {
                setChooseOptions((pre) => ({
                  ...pre,
                  show: true,
                  type: "family",
                }));
              }}
            >
              Family Based
            </div>
            <div
              className="btn-content"
              onClick={() => {
                setFormObjResgisterType("myself");
                navigate("/myself");
              }}
            >
              Other
            </div>
          </>
        ) : (
          <>
            <div
              className="btn-content m-w-450"
              onClick={() => {
                setFormObjResgisterType("petitioner");
                navigate("/petitioner");
              }}
            >
              {chooseOptions.type === "employee"
                ? "Are you the Employer (Petitioner)?"
                : `Are you the U.S. Citizen Sponsor (Petitioner)?`}
            </div>

            <div
              className="btn-content m-w-450"
              onClick={() => {
                setFormObjResgisterType("myself");
                navigate("/myself");
              }}
            >
              {chooseOptions.type === "employee"
                ? "Are you the Foreign National Employee (Beneficiary)?"
                : "Are you the Foreign National Relative (Beneficiary)?"}
            </div>
          </>
        )}
      </div>
      <div
        className="back-btn"
        onClick={() =>
          chooseOptions.show
            ? setChooseOptions((pre) => ({
                ...pre,
                show: false,
                type: "",
              }))
            : navigate("/account")
        }
      >
        Back
      </div>
    </React.Fragment>
  );
};
