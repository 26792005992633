import { FormHelperText } from "@mui/material";
import _ from "lodash";
import React, { ReactElement, useEffect, useState } from "react";
// import Select from 'react-dropdown-select';
import Select, { components, NoticeProps } from "react-select";

type InputProps = {
  placeHolder?: string;
  className?: string;
  onChange?: (src: any) => void;
  extraStyle?: any;
  value?: any;
  error?: any;
  options?: any;
  labelKey?: string;
  valueKey?: string;
  id?: string;
  name?: string;
  ref?: any;
  disabled?: boolean;
  selectedValue?: string;
  autoFocus?: boolean;
  searchable?: boolean;
  onClose?: any;
  tabIndex?: number;
  onFocus?: (src: any) => void;
  sorting?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  closeMenuOnSelect?: boolean;
};

const NoOptionsMessage = (props: NoticeProps) => {
  return (
    <components.NoOptionsMessage {...props}>
      <p>No Options Available</p>
    </components.NoOptionsMessage>
  );
};

const CustomSelect = (props: InputProps): ReactElement => {
  const {
    placeHolder,
    onChange,
    value = "",
    error,
    options,
    labelKey = "label",
    valueKey = "value",
    id,
    name,
    ref,
    disabled,
    autoFocus = false,
    searchable = true,
    isClearable = true,
    onFocus,
    tabIndex,
    sorting,
    isMulti = false,
    closeMenuOnSelect = true,
  } = props;
  const [option, setOption] = useState([]);

  useEffect(() => {
    setOption(
      sorting
        ? options
            .filter(
              (ch: any) =>
                !_.isNull(ch[valueKey]) || !_.isUndefined(ch[valueKey])
            )
            .map((e: any) => ({
              ...e,
              label: e[labelKey] ? String(e[labelKey]) : "",
              [labelKey]: e[labelKey] ? String(e[labelKey]) : "",
              value: e[valueKey],
            }))
            .sort((a: any, b: any) =>
              String(a[labelKey]).toLowerCase() >
              String(b[labelKey]).toLowerCase()
                ? 1
                : -1
            )
        : options
            .filter(
              (ch: any) =>
                !_.isNull(ch[valueKey]) || !_.isUndefined(ch[valueKey])
            )
            .map((e: any) => ({
              ...e,
              label: e[labelKey] ? String(e[labelKey]) : "",
              [labelKey]: e[labelKey] ? String(e[labelKey]) : "",
              value: e[valueKey],
            }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);
  return (
    <>
      <Select
        autoFocus={autoFocus}
        closeMenuOnSelect={closeMenuOnSelect}
        isClearable={isClearable}
        isSearchable={searchable}
        id={id}
        ref={ref}
        isMulti={isMulti}
        onFocus={onFocus && onFocus}
        styles={customStyles}
        isDisabled={disabled}
        name={name}
        tabIndex={tabIndex}
        placeholder={placeHolder}
        components={{ NoOptionsMessage }}
        {...(value !== "" || isMulti
          ? {
              value: isMulti
                ? value && Array.isArray(value) && value.length
                  ? option.filter((el) => value.includes(el[valueKey]))
                  : null
                : option &&
                  option.length &&
                  value &&
                  option.find((el) => String(el[valueKey]) === String(value))
                ? option.find((el) => String(el[valueKey]) === String(value))
                : null,
            }
          : {})}
        options={!_.isEmpty(option) && option.length ? option : []}
        onChange={(e: any, ac) => {
          if (isMulti) {
            onChange &&
              onChange(
                e
                  ? {
                      target: {
                        value:
                          e && Array.isArray(e) && e.length
                            ? e.map((e) => e[valueKey])
                            : null,
                      },
                    }
                  : { target: { value: "" } }
              );
            return;
          }
          onChange &&
            onChange(
              e ? { target: { value: e["value"] } } : { target: { value: "" } }
            );
        }}
      />
      {error && (
        <FormHelperText style={{ color: "#ff0000", marginLeft: "12px" }}>
          {error}
        </FormHelperText>
      )}
    </>
  );
};
const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    border: "1px solid #002C69",
    borderRadius: "15px !important",
    fontSize: "14px !important",
    minHeight: "48px !important",
    padding: "1px 16px",
  }),
  indicatorContainer: (provided: any, state: any) => ({
    padding: "6px !important",
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    display: "none",
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    zIndex: "10000",
    fontSize: "14px !important",
    fontWeight: "500 !important",
  }),
  menuList: (provided: any, state: any) => ({
    ...provided,
    maxHeight: "110px !important",
  }),
  // multiValue: (provided, state) => ({
  //   ...provided,
  //   background: ' #0096fb',
  //   borderRadius: ' 11px',
  //   color: ' #fff !important',
  //   div: {
  //     ':hover': {
  //       ':Svg': {
  //         backgroundColor: '0096fb',
  //       },
  //       color: '#fff',
  //     },
  //     backgroundColor: 'none',
  //     color: '#fff',
  //   },
  //   fontSize: ' 13px',
  //   marginBottom: ' 5px',
  //   marginRight: '5px',
  //   padding: '4px 10px',
  // }),
  placeholder: (provided: any) => ({
    ...provided,
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: "#D5D5D5 !important",
    fontWeight: "700 !important",
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontSize: "16px !important",
    color: "#002C69 !important",
    fontWeight: "600 !important",
  }),
};
export default CustomSelect;
