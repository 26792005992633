import { toast } from "react-toastify";
import { alertOptions } from "./constant";

const FormBodypayload = {
  applyingFor: "",
  sendMail: false,
  petitionerContactId: null,
  petitionerContactDetails: [],
  petitionerAssociated: false,
  orgType: "",
  orgName: "",
  leadOrgType: "new",
  contactPersonName: "",
  contactMobileCountryCode: "",
  contactMobileNumber: "",
  contactMobileSocCode: "",
  contactLastName: "",
  contactEmail: "",
  beneficiaryEditDetails: [],
  beneficiaryDetails: [],
  affiliateType: "new",
  affilateDetails: [],
  attorneyId: 3,
  additionalAttorneyContacts: [],
  attorneyContactId: 7,
  attorneySupport: [37],
  caseManager: [2606],
  convictions: false,
  convictionsExplain: null,
  employmentOffered: false,
  employmentOfferedExplain: null,
  fillingType: null,
  leadEmail: "",
  leadNotes: "",
  leadStatus: "prospect",
  numberOfDependents: null,
  overstayUsVisa: false,
  overstayUsVisaExplain: null,
  premiumProcessing: "no",
  processingType: null,
  totalEmployeesInCompany: null,
  visaDenials: false,
  visaDenialsExplain: null,
  visaType: [],
};
let postObj = JSON.parse(JSON.stringify(FormBodypayload));
export const setBodyValues = (obj: any) => {
  return (postObj = {
    ...postObj,
    ...obj,
  });
};

export const getFormVales = () => postObj;

export const resetFormValues = () =>
  (postObj = JSON.parse(JSON.stringify(FormBodypayload)));

export const getPayload = () => ({
  ...postObj,
  ...(postObj.applyingFor === "petitioner" ? { beneficiaryDetails: [] } : {}),
  ...(postObj.applyingFor === "myself"
    ? {
        orgType: "",
        orgName: "",
        leadOrgType: "",
        contactPersonName: "",
        contactMobileCountryCode: "",
        contactMobileNumber: "",
        contactMobileSocCode: "",
        contactLastName: "",
        contactEmail: "",
      }
    : {}),
  ...(postObj.visaType === 1
    ? {
        premiumProcessing: postObj.premiumProcessing,
        processingType: postObj.processingType ? postObj.processingType : null,
        fillingType: postObj.fillingType ? postObj.fillingType : null,
        numberOfDependents: postObj.numberOfDependents
          ? postObj.numberOfDependents
          : null,
        totalEmployeesInCompany: postObj.totalEmployeesInCompany
          ? postObj.totalEmployeesInCompany
          : null,
      }
    : {}),
});

export const getFirmDetails = (id: string) => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_APIURL}/attorney/profile/name/${id}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const resp = await res.json();
        if (res.ok) {
          resolve(resp);
        } else {
          let errorMessage =
            resp && resp?.message
              ? "We are unable to reach the server. Please try after sometime"
              : "Something went wrong!";
          if (resp?.error?.message) {
            if (typeof resp?.error?.message === "string") {
              errorMessage = resp.error.message;
            } else {
              errorMessage = resp.error.message.toString();
            }
          }
          toast.error(errorMessage || String(resp), alertOptions);
          reject(resp);
        }
        return res;
      })
      .catch((err) => {
        let errorMessage =
          err && err?.message
            ? "We are unable to reach the server. Please try after sometime"
            : "Something went wrong!";
        if (err?.error?.message) {
          if (typeof err?.error?.message === "string") {
            errorMessage = err.error.message;
          } else {
            // Array type of message
            errorMessage = err.error.message.toString();
          }
        }
        toast.error(errorMessage || String(err), alertOptions);
        reject(errorMessage);
        return err;
      });
  });
};
export const apiCall = (id: string) => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_APIURL}/common/prospect/${id}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(getPayload()), // body data type must match "Content-Type" header
    })
      .then(async (res) => {
        const resp = await res.json();
        if (res.ok) {
          resolve(resp);
        } else {
          let errorMessage =
            resp && resp?.message
              ? "We are unable to reach the server. Please try after sometime"
              : "Something went wrong!";
          if (resp?.error?.message) {
            if (typeof resp?.error?.message === "string") {
              errorMessage = resp.error.message;
            } else {
              errorMessage = resp.error.message.toString();
            }
          }
          if (errorMessage === "This Petitioner already accepted your Invite") {
            reject(errorMessage);
            return resp;
          }
          toast.error(errorMessage || String(resp), alertOptions);
          reject(errorMessage);
        }
        return res;
      })
      .catch((err) => {
        let errorMessage =
          err && err?.message
            ? "We are unable to reach the server. Please try after sometime"
            : "Something went wrong!";
        if (err?.error?.message) {
          if (typeof err?.error?.message === "string") {
            errorMessage = err.error.message;
          } else {
            // Array type of message
            errorMessage = err.error.message.toString();
          }
        }
        if (errorMessage === "This Petitioner already accepted your Invite") {
          reject(errorMessage);
          return err;
        }
        toast.error(errorMessage || String(err), alertOptions);
        reject(errorMessage);
        return err;
      });
  });
};

// orgType: "new",
// orgName: "My New Org",
// leadOrgType: "new",
// contactPersonName: "Ragavan",
// contactMobileCountryCode: "+1",
// contactMobileNumber: "(551) 545-4545",
// contactMobileSocCode: "us",
// contactLastName: "Rs",
// contactEmail: "ragavan.s+78@7t.co",

// beneficiaryFirstName: "newl added ins",
// beneficiaryEmail: "ragavan.s+78@7t.co",
// beneficiaryLastName: "My Lead Bene",
// beneficiaryMobileCode: "us",
// beneficiaryMobileNumber: "",
// checkEmailValidate: "",
// disable: false,
// id: "",
// ids: "",
// leadUserId: "",
// loginCheck: false,
// mobileCountryCode: "+1 ",
// mobileSocCode: "us",
// sendEmailDisable: false,
// sendMail: true,
// status: "",
// type: "new",

// visaType: [1],
// leadNotes: "",
// leadStatus: "prospect",

// premiumProcessing: "yes",
// processingType: 1,
// numberOfDependents: "45",
// totalEmployeesInCompany: "500",
// fillingType: 2,

//  _________________ Default Values
// attorneyId: 3,
// attorneyContactId: 7,
// attorneySupport: [37],
// caseManager: [2606],
