import React, { useContext } from "react";
import "./index.scss";
import { Header } from "../../components/header";
import success from "../../assets/agreement_sign.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MyContext } from "../../MyContext";
import { resetFormValues } from "../../utils/store";

export const Final = () => {
  const navigate = useNavigate();
  const [queryparams] = useSearchParams();
  const { firmDetails } = useContext<any>(MyContext);
  if (queryparams.get("error")) {
  }
  return (
    <React.Fragment>
      <Header className="header_container_final" />
      <div className="final-container">
        <div className="fl-1">
          <img
            className="chat_avatar"
            src="https://www.w3schools.com/howto/img_avatar.png"
            alt=""
          />
        </div>
        <div className="content">
          <div className="congates_img">
            <img className="" src={success} alt="" />
          </div>
          <div className="description">
            {queryparams.get("error")
              ? `Hey there! It looks like you already have an existing account with ${
                  firmDetails && firmDetails.lawFirmName
                    ? firmDetails.lawFirmName
                    : ""
                }. Tap 'Let's Go!' to login to your account.`
              : `  Thank you for sharing your information to me. Please provide me
            sometime to process your information and connect you with one of our
            Visa Processing Specialists. We will email you with the details as
            soon as our Lead Attorney reviews your details.`}
          </div>

          <div
            className={`btn p-30 ${
              queryparams.get("error") ? " btn-center" : ""
            }`}
          >
            {queryparams.get("error") && (
              <button
                type="submit"
                className="btn_color"
                onClick={() => {
                  resetFormValues();
                  window.open("https://app.visas.ai/", "_blank");
                }}
              >
                Let's Go!
              </button>
            )}
            <button
              type="submit"
              className="btn_color"
              onClick={() => {
                resetFormValues();
                navigate("/account");
              }}
            >
              Submit another inquiry
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
