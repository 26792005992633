import React, { useEffect } from "react";
import { FormHelperText, Grid, Typography, useMediaQuery } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInput from "../../components/customInput";
import { myselfFormSchema } from "../../utils/validatorSchema";
import "./index.scss";
import { Header } from "../../components/header";
import { Footer } from "../../components/footer";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { getFormVales, setBodyValues } from "../../utils/store";

export const Myself = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: "all",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      phoneObj: "",
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(myselfFormSchema as any),
  });
  const sumitForm = (formValue: any) => {
    const savedObj: any = getFormVales();
    const beneficiaryArrObj = {
      beneficiaryFirstName: formValue.firstName,
      beneficiaryEmail: formValue.email,
      beneficiaryLastName: formValue.lastName ? formValue.lastName : "",
      // Number only we fetced in esixting key values
      ...(formValue?.phoneObj
        ? {
            beneficiaryMobileCode: formValue?.phoneObj?.countryCode,
            mobileCountryCode: `+${formValue?.phoneObj?.dialCode}`,
            mobileSocCode: formValue?.phoneObj?.countryCode,
          }
        : savedObj.beneficiaryMobileCode &&
          savedObj.mobileCountryCode &&
          savedObj.mobileSocCode
        ? {
            beneficiaryMobileCode: savedObj.beneficiaryMobileCode,
            mobileCountryCode: savedObj.mobileCountryCode,
            mobileSocCode: savedObj.mobileSocCode,
          }
        : {
            beneficiaryMobileCode: "",
            mobileCountryCode: "",
            mobileSocCode: "",
          }),
      beneficiaryMobileNumber: formValue.phone
        ? String(formValue.phone).split(/(?<=^\S+)\s/)[1]
        : "",
      checkEmailValidate: "",
      disable: false,
      id: "",
      ids: "",
      leadUserId: "",
      loginCheck: false,
      sendEmailDisable: false,
      sendMail: false,
      status: "",
      type: "new",
    };
    setBodyValues({ beneficiaryDetails: [beneficiaryArrObj] });
    navigate("/visatype");
  };
  useEffect(() => {
    const savedObj: any = getFormVales();
    if (
      savedObj.beneficiaryDetails &&
      Array.isArray(savedObj.beneficiaryDetails)
    ) {
      setValue(
        "firstName",
        savedObj.beneficiaryDetails[0]?.beneficiaryFirstName
      );
      setValue("lastName", savedObj.beneficiaryDetails[0]?.beneficiaryLastName);

      setValue("email", savedObj.beneficiaryDetails[0]?.beneficiaryEmail);
      if (
        savedObj.beneficiaryDetails[0]?.mobileCountryCode &&
        savedObj.beneficiaryDetails[0]?.beneficiaryMobileNumber
      ) {
        setValue(
          "phone",
          `+${savedObj.beneficiaryDetails[0]?.mobileCountryCode} ${savedObj.beneficiaryDetails[0]?.beneficiaryMobileNumber}`
        );
      }
    }
  }, [setValue]);
  return (
    <React.Fragment>
      <Header chatTitle="Please provide your contact information" />
      <div className="myself_container">
        <div className="header">Beneficiary</div>
        <form onSubmit={handleSubmit(sumitForm)}>
          <Grid
            container
            columnSpacing={isMobile ? 1 : 2}
            rowSpacing={isMobile ? 1 : 2}
          >
            <Grid item xs={12} md={6} sm={12}>
              <Typography component={"div"} className="custom-label">
                First Name
              </Typography>
              <Controller
                control={control}
                defaultValue={""}
                name={"firstName"}
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <CustomInput
                      placeHolder={"First name"}
                      name={name}
                      value={value}
                      className={"custom-input"}
                      error={errors.firstName?.message || ""}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <Typography component={"div"} className="custom-label">
                Last Name
              </Typography>
              <Controller
                control={control}
                defaultValue={""}
                name={"lastName"}
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <CustomInput
                      placeHolder={"Last name"}
                      name={name}
                      value={value}
                      className={"custom-input"}
                      error={errors.lastName?.message || ""}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <Typography component={"div"} className="custom-label">
                Email
              </Typography>
              <Controller
                control={control}
                defaultValue={""}
                name={"email"}
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <CustomInput
                      placeHolder={"Email"}
                      name={name}
                      value={value}
                      className={"custom-input"}
                      error={errors.email?.message || ""}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <Typography component={"div"} className="custom-label">
                Phone
              </Typography>
              <Controller
                control={control}
                defaultValue={""}
                name={"phone"}
                render={({ field: { value, onChange, ref } }) => {
                  return (
                    <PhoneInput
                      {...ref}
                      value={value}
                      placeholder="Phone"
                      country={"us"}
                      autoFormat={true}
                      countryCodeEditable={false}
                      enableSearch={true}
                      onChange={(value, data, evet, format) => {
                        onChange(format);
                        setValue("phoneObj", data);
                      }}
                    />
                  );
                }}
              />
              {errors?.phone?.message && (
                <FormHelperText
                  style={{ color: "#ff0000", marginLeft: "12px" }}
                >
                  {String(errors.phone?.message)}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </form>
      </div>
      <Footer submit={() => handleSubmit(sumitForm)()} />
    </React.Fragment>
  );
};
