import React, { useContext, useEffect } from "react";
import { Header } from "../../components/header";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInput from "../../components/customInput";
import CustomSelect from "../../components/customSelect";
import { Footer } from "../../components/footer";
import { premiumProcessingSchema } from "../../utils/validatorSchema";
import "./index.scss";
import {
  apiCall,
  getFormVales,
  resetFormValues,
  setBodyValues,
} from "../../utils/store";
import { processingFiling, processingTypeList } from "../../utils/constant";
import { MyContext } from "../../MyContext";

export const PremiumProcessing = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = React.useState(false);
  const { firmunqiue } = useContext<any>(MyContext);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: "all",
    defaultValues: {
      premiumProcessing: "",
      processingType: "",
      fillingType: "",
      numberOfDependents: "",
      totalEmployeesInCompany: "",
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(premiumProcessingSchema as any),
  });
  const sumitForm = async (formValue: any) => {
    try {
      setLoader(true);
      const processingObj = {
        premiumProcessing: formValue.premiumProcessing,
        processingType: formValue.processingType
          ? formValue.processingType
          : null,
        fillingType: formValue.fillingType ? formValue.fillingType : null,
        numberOfDependents: formValue.numberOfDependents
          ? formValue.numberOfDependents
          : null,
        totalEmployeesInCompany: formValue.totalEmployeesInCompany
          ? formValue.totalEmployeesInCompany
          : null,
      };
      setBodyValues(processingObj);
      await apiCall(firmunqiue);
      navigate("/final-success");
      resetFormValues();
      setLoader(false);
    } catch (error) {
      if (error === "This Petitioner already accepted your Invite") {
        navigate(
          "/final-success?error=This Petitioner already accepted your Invite"
        );
      }
      setLoader(false);
    }
  };
  useEffect(() => {
    const savedObj: any = getFormVales();
    if (
      savedObj?.premiumProcessing ||
      savedObj?.processingType ||
      savedObj?.fillingType ||
      savedObj?.numberOfDependents ||
      savedObj?.totalEmployeesInCompany
    ) {
      setValue("premiumProcessing", savedObj?.premiumProcessing);
      setValue("processingType", savedObj?.processingType);
      setValue("fillingType", savedObj?.fillingType);
      setValue("numberOfDependents", savedObj?.numberOfDependents);
      setValue("totalEmployeesInCompany", savedObj?.totalEmployeesInCompany);
    }
  }, [setValue]);
  return (
    <React.Fragment>
      {loader && <div className="liberty-loader" />}
      <Header chatTitle="We are almost done. Answer the below questions if you are looking for H-1B." />
      <div className="premium_container">
        <div className="header">Do you need Premium Processing ?</div>
        <div className="button_container">
          <button
            type="submit"
            className="btn_color"
            style={
              watch("premiumProcessing") === "yes"
                ? { background: "#31edb3 0% 0% no-repeat padding-box" }
                : {}
            }
            onClick={() => setValue("premiumProcessing", "yes")}
          >
            Yes
          </button>
          <button
            type="submit"
            className="btn_color"
            style={
              watch("premiumProcessing") === "no"
                ? { background: "#31edb3 0% 0% no-repeat padding-box" }
                : {}
            }
            onClick={() => setValue("premiumProcessing", "no")}
          >
            No
          </button>
        </div>
        <form onSubmit={handleSubmit(sumitForm)}>
          <Grid container columnSpacing={4.5} rowSpacing={2}>
            <Grid item xs={12} md={6} sm={12}>
              <Typography component={"div"} className="custom-label">
                Type of Processing
              </Typography>
              <Controller
                control={control}
                defaultValue={""}
                name={"processingType"}
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <CustomSelect
                      labelKey={"processing_type"}
                      valueKey={"id"}
                      options={
                        processingTypeList && processingTypeList.length
                          ? processingTypeList
                          : []
                      }
                      name={name}
                      value={value}
                      onChange={(val) => onChange(val)}
                      placeHolder={"Select"}
                      className={"custom-input"}
                      error={errors.processingType?.message}
                      searchable={true}
                      tabIndex={1}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <Typography component={"div"} className="custom-label">
                Type Of Filling
              </Typography>
              <Controller
                control={control}
                defaultValue={""}
                name={"fillingType"}
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <CustomSelect
                      labelKey={"filing_type"}
                      valueKey={"id"}
                      options={
                        processingFiling && processingFiling.length
                          ? processingFiling
                          : []
                      }
                      name={name}
                      value={value}
                      onChange={(val) => onChange(val)}
                      placeHolder={"Select"}
                      className={"custom-input"}
                      error={errors.fillingType?.message}
                      searchable={true}
                      tabIndex={2}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <Typography component={"div"} className="custom-label">
                No. Of Filings with
              </Typography>
              <Controller
                control={control}
                defaultValue={""}
                name={"numberOfDependents"}
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <CustomInput
                      placeHolder={"Number of Dependents"}
                      name={name}
                      value={value}
                      className={"custom-input"}
                      error={errors.numberOfDependents?.message || ""}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  );
                }}
              />
              <Typography component={"div"} className="info">
                (Spouse or child and only if the beneficiary and dependent are
                in the U.S.)
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <Typography component={"div"} className="custom-label">
                Number of Employees in the U.S. Company Today
              </Typography>
              <Controller
                control={control}
                defaultValue={""}
                name={"totalEmployeesInCompany"}
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <CustomInput
                      placeHolder={"Number of Employees"}
                      name={name}
                      value={value}
                      className={"custom-input"}
                      error={errors.totalEmployeesInCompany?.message || ""}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </form>
      </div>
      <Footer submit={() => handleSubmit(sumitForm)()} submitbtn={true} />
    </React.Fragment>
  );
};
