import React, { useEffect } from "react";
import { FormHelperText, Grid, Typography, useMediaQuery } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInput from "../../components/customInput";
import { petitionerFormSchema } from "../../utils/validatorSchema";
import "./index.scss";
import { Header } from "../../components/header";
import { Footer } from "../../components/footer";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { getFormVales, setBodyValues } from "../../utils/store";

export const PetitionerForm = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: "all",
    defaultValues: {
      orgnization: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      phoneObj: "",
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(petitionerFormSchema as any),
  });
  const sumitForm = (formValue: any) => {
    const savedObj: any = getFormVales();
    const petitionerObj = {
      orgType: "new",
      orgName: formValue?.orgnization,
      leadOrgType: "new",
      // Number only we fetced in esixting key values
      ...(formValue?.phoneObj
        ? {
            contactMobileSocCode: formValue?.phoneObj?.countryCode,
            contactMobileCountryCode: `+${formValue?.phoneObj?.dialCode}`,
          }
        : savedObj.contactMobileCountryCode && savedObj?.contactMobileSocCode
        ? {
            contactMobileCountryCode: savedObj.contactMobileCountryCode,
            contactMobileSocCode: savedObj?.contactMobileSocCode,
          }
        : { contactMobileCountryCode: "", contactMobileSocCode: "" }),
      contactPersonName: formValue?.firstName,
      contactMobileNumber: formValue.phone
        ? String(formValue.phone).split(/(?<=^\S+)\s/)[1]
        : "",
      contactLastName: formValue?.lastName ? formValue?.lastName : "",
      contactEmail: formValue?.email,
    };
    setBodyValues(petitionerObj);
    navigate("/visatype");
  };
  useEffect(() => {
    const savedObj: any = getFormVales();
    if (
      savedObj?.contactPersonName ||
      savedObj?.contactLastName ||
      savedObj?.contactEmail ||
      savedObj?.contactMobileCountryCode ||
      savedObj?.contactMobileNumber ||
      savedObj?.orgName
    ) {
      setValue("orgnization", savedObj?.orgName);
      setValue("firstName", savedObj.contactPersonName);
      setValue("lastName", savedObj?.contactLastName);
      setValue("email", savedObj.contactEmail);
      if (savedObj.contactMobileCountryCode && savedObj.contactMobileNumber) {
        setValue(
          "phone",
          `${savedObj.contactMobileCountryCode} ${savedObj.contactMobileNumber}`
        );
      }
    }
  }, [setValue]);
  return (
    <React.Fragment>
      <Header chatTitle="Please provide your contact information." />
      <div className="petitioner_container">
        <div className="header">Petitioner</div>
        <form onSubmit={handleSubmit(sumitForm)}>
          <Grid
            container
            columnSpacing={isMobile ? 1 : 2}
            rowSpacing={isMobile ? 1 : 2}
          >
            <Grid item xs={12} md={12} sm={12}>
              <Typography component={"div"} className="custom-label">
                Organization Name
              </Typography>
              <Controller
                control={control}
                defaultValue={""}
                name={"orgnization"}
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <CustomInput
                      placeHolder={"Organization Name"}
                      name={name}
                      value={value}
                      className={"custom-input"}
                      error={errors.orgnization?.message || ""}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <Typography component={"div"} className="custom-label">
                First Name
              </Typography>
              <Controller
                control={control}
                defaultValue={""}
                name={"firstName"}
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <CustomInput
                      placeHolder={"First name"}
                      name={name}
                      value={value}
                      className={"custom-input"}
                      error={errors.firstName?.message || ""}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <Typography component={"div"} className="custom-label">
                Last Name
              </Typography>
              <Controller
                control={control}
                defaultValue={""}
                name={"lastName"}
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <CustomInput
                      placeHolder={"Last name"}
                      name={name}
                      value={value}
                      className={"custom-input"}
                      error={errors.lastName?.message || ""}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <Typography component={"div"} className="custom-label">
                Email
              </Typography>
              <Controller
                control={control}
                defaultValue={""}
                name={"email"}
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <CustomInput
                      placeHolder={"Email"}
                      name={name}
                      value={value}
                      className={"custom-input"}
                      error={errors.email?.message || ""}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <Typography component={"div"} className="custom-label">
                Phone
              </Typography>
              <Controller
                control={control}
                defaultValue={""}
                name={"phone"}
                render={({ field: { value, onChange, ref } }) => {
                  return (
                    <PhoneInput
                      {...ref}
                      placeholder="Phone"
                      country={"us"}
                      value={value}
                      autoFormat={true}
                      countryCodeEditable={false}
                      enableSearch={true}
                      onChange={(value, data, evet, format) => {
                        onChange(format);
                        setValue("phoneObj", data);
                      }}
                    />
                  );
                }}
              />
              {errors?.phone?.message && (
                <FormHelperText
                  style={{ color: "#ff0000", marginLeft: "12px" }}
                >
                  {String(errors.phone?.message)}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </form>
      </div>
      <Footer submit={() => handleSubmit(sumitForm)()} />
    </React.Fragment>
  );
};
