import React, { useContext } from "react";
import { Header } from "../../components/header";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import { MyContext } from "../../MyContext";

export const ExistingAccount = () => {
  const navigate = useNavigate();
  const { firmDetails } = useContext<any>(MyContext);
  return (
    <React.Fragment>
      <Header
        chatTitle="Hi! I’m Ovie, your Intelligent Immigration Guide. <br/>
I am here to help you through
your Immigration Journey.
Please answer the following
questions for me to understand
your needs."
      />
      <div className="existing_content">
        <div className="heading">
          Do you have an existing{" "}
          {firmDetails && firmDetails.lawFirmName
            ? firmDetails.lawFirmName
            : ""}{" "}
          Visas.AI Account?
        </div>

        <div
          className="btn-content"
          onClick={() => window.open("https://app.visas.ai/", "_blank")}
        >
          Yes, I have an account
        </div>

        <div className="btn-content w-303" onClick={() => navigate("/home")}>
          No, I do not have an account
        </div>
      </div>
    </React.Fragment>
  );
};
