export const alertOptions: any = {
  autoClose: 5000,
  closeOnClick: true,
  draggable: true,
  hideProgressBar: false,
  pauseOnHover: true,
  position: "top-right",
  progress: undefined,
};

export const visaTypeList = {
  total: 92,
  data: [
    {
      visa_id: 95,
      visa_code:
        "212 Waiver Permission to Reapply After Deportation or Removal",
      configure_visa_form_id: 95,
      visa_description:
        "I-212 case to obtain permission to reapply to lawfully return to US. ",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [321],
      petitioner_section: [116, 323],
      caseplan_section: null,
      strategy_section: [316],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-07-27T18:33:49.000Z",
      created_at: "2023-07-26T21:42:40.000Z",
    },
    {
      visa_id: 96,
      visa_code: "601A Waiver",
      configure_visa_form_id: 96,
      visa_description: "Waiver for unlawful presence filed within US",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [325],
      petitioner_section: [116, 327],
      caseplan_section: null,
      strategy_section: [319],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-07-27T18:33:23.000Z",
      created_at: "2023-07-27T18:31:30.000Z",
    },
    {
      visa_id: 94,
      visa_code: "601 Waiver",
      configure_visa_form_id: 94,
      visa_description:
        "Process to forgive ineligibilities to obtain an Immigration status",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [324],
      petitioner_section: [116, 326],
      caseplan_section: null,
      strategy_section: [320],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2023-07-25T18:30:55.000Z",
    },
    {
      visa_id: 62,
      visa_code: "Adjustment of Status",
      configure_visa_form_id: 62,
      visa_description:
        "This package of forms includes the I-485 to adjust from certain non-immigrant visas in the US to permanent residency. It also includes an I-131 for advanced parole which permits travel and I-765 for employment if applicable. ",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [167, 168, 166],
      petitioner_section: [116],
      caseplan_section: null,
      strategy_section: [],
      contract_section: null,
      dependents_section: [318],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-12-21T14:28:00.000Z",
      created_at: "2022-04-19T11:26:28.000Z",
    },
    {
      visa_id: 67,
      visa_code: "Afghan Asylum under OAR-Expeditious Processing",
      configure_visa_form_id: 67,
      visa_description:
        "FOR AFGHAN CITIZENS AND NATIONALS, AND THOSE WITH NO NATIONALITY BUT WERE RESIDING IN AFGHANISTAN\r\n\r\n1.\tMUST FILE WITHIN ONE YEAR OF ENTRY INTO U.S. UNLESS CAN SHOW CHANGED CIRCUMSTANCES\r\n2.\t INITIAL ASYLUM INTERVIEW WILL BE CONDUCTED WITHIN 45 DAYS OF FILING\r\n3.\tCOMPLETE ADJUDICATION OF ASYLUM APPLICATION TO BE COMPLETED IN 150 DAYS\r\n",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [185, 186, 193, 194, 188, 189, 191, 192],
      petitioner_section: [116, 302, 301],
      caseplan_section: null,
      strategy_section: [176],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-02-07T04:27:19.000Z",
      created_at: "2022-04-20T19:42:10.000Z",
    },
    {
      visa_id: 60,
      visa_code: "Afghan/Iraqi SIV as Translator or Employees of US or ISAF",
      configure_visa_form_id: 60,
      visa_description:
        "3 Categories\r\n1) AFGHANISTAN OR IRAQ NATIONAL WHO WORKED WITH THE U.S.ARMED FORCES AS A \r\nTRANSLATOR \r\n2) IRAQ NATIONAL WHO WORKED FOR OR ON BEHALF OF THE U.S. GOVERNMENT IN IRAQ \r\n3) AFGHANISTAN NATIONAL WHO WORKED FOR OR ON BEHALF OF THE U.S. \r\nGOVERNMENT OR THE INTERNATIONAL SECURITY ASSISTANCE FORCE (ISAF) IN AFGHANISTAN \r\n",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [185, 188, 189, 191, 192, 194, 193, 186],
      petitioner_section: [116, 302, 301],
      caseplan_section: null,
      strategy_section: [171],
      contract_section: null,
      dependents_section: [143, 140, 144],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-02-07T04:28:06.000Z",
      created_at: "2022-04-17T18:39:41.000Z",
    },
    {
      visa_id: 61,
      visa_code: "Asylum",
      configure_visa_form_id: 61,
      visa_description:
        "Asylum is for those who come to the United States seeking protection because they have suffered persecution or fear that they will suffer persecution due to:\r\n\r\nRace\r\nReligion\r\nNationality\r\nMembership in a particular social group\r\nPolitical opinion",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [185, 186, 193, 194, 188, 189, 191, 192],
      petitioner_section: [116, 302, 301],
      caseplan_section: null,
      strategy_section: [172],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-02-07T04:28:24.000Z",
      created_at: "2022-04-18T01:39:00.000Z",
    },
    {
      visa_id: 55,
      visa_code: "B-1/B-2 Consular Filing",
      configure_visa_form_id: 55,
      visa_description: "B-1/B-2 Consular Filing",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [229, 157],
      petitioner_section: [116, 302, 301],
      caseplan_section: null,
      strategy_section: [217],
      contract_section: null,
      dependents_section: [318],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-02-09T19:57:42.000Z",
      created_at: "2021-06-24T10:48:59.000Z",
    },
    {
      visa_id: 20,
      visa_code: "B-1/ B-2 USCIS filing",
      configure_visa_form_id: 20,
      visa_description: "B-1 or B-2 Visa Extension in US",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: true,
      unpopulate_section: null,
      beneficiary_section: [138],
      petitioner_section: [116, 302, 301],
      caseplan_section: null,
      strategy_section: [53],
      contract_section: null,
      dependents_section: [139, 318],
      lca_section: null,
      dependent_as_normal_section: [138],
      additional_document_template: [109],
      modified_at: "2023-03-12T17:44:50.000Z",
      created_at: "2020-12-09T14:10:17.339Z",
    },
    {
      visa_id: 21,
      visa_code: "B-1 Dometic Employee",
      configure_visa_form_id: 21,
      visa_description: "B-1 Domestic Employee",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: true,
      unpopulate_section: null,
      beneficiary_section: null,
      petitioner_section: [116, 137, 302, 301],
      caseplan_section: null,
      strategy_section: [],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-02-07T04:30:16.000Z",
      created_at: "2020-12-09T14:10:17.339Z",
    },
    {
      visa_id: 46,
      visa_code: "CITIZENSHIP",
      configure_visa_form_id: 46,
      visa_description: "CITIZENSHIP",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [
        235, 237, 239, 238, 240, 242, 241, 243, 244, 245, 246, 247, 253, 252,
        248, 251, 236,
      ],
      petitioner_section: [116, 254, 302, 301],
      caseplan_section: null,
      strategy_section: null,
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2020-12-10T07:10:09.000Z",
    },
    {
      visa_id: 80,
      visa_code: "DACA",
      configure_visa_form_id: 80,
      visa_description: "Deferred Adjudication for Childhood Arrivals",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [168, 281, 282, 284],
      petitioner_section: [283, 302, 301],
      caseplan_section: null,
      strategy_section: [256, 267],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-04-19T20:09:26.000Z",
      created_at: "2023-03-21T22:04:48.000Z",
    },
    {
      visa_id: 51,
      visa_code: "DACA Advanced Parole",
      configure_visa_form_id: 51,
      visa_description:
        "Travel document for DACA recipients to leave and return to USA. ",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: null,
      petitioner_section: [116, 302, 301],
      caseplan_section: null,
      strategy_section: null,
      contract_section: null,
      dependents_section: [77, 78, 79, 80, 81, 82, 83, 84, 85],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2021-02-24T00:43:18.000Z",
    },
    {
      visa_id: 85,
      visa_code: "DACA Renewal",
      configure_visa_form_id: 85,
      visa_description: null,
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [267],
      petitioner_section: [302, 301],
      caseplan_section: null,
      strategy_section: [277],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-04-19T20:07:33.000Z",
      created_at: "2023-04-19T20:06:25.000Z",
    },
    {
      visa_id: 77,
      visa_code: "E-1 Employee Consulate Processing",
      configure_visa_form_id: 77,
      visa_description: "E-1 Employee Consulate Processing",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [15, 2, 1, 157, 233, 249, 257, 358],
      petitioner_section: [6, 5, 8, 301, 302],
      caseplan_section: null,
      strategy_section: [52, 232],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-03-06T10:11:03.000Z",
      created_at: "2023-03-06T10:09:11.000Z",
    },
    {
      visa_id: 79,
      visa_code: "E-1 Employee USCIS Processing",
      configure_visa_form_id: 79,
      visa_description: "E-1 Employee USCIS Processing",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [15, 1, 2],
      petitioner_section: [6, 5, 8, 302, 301],
      caseplan_section: null,
      strategy_section: [52, 232],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2023-03-06T10:09:48.000Z",
    },
    {
      visa_id: 22,
      visa_code: "E-1 Trader Consulate Processing",
      configure_visa_form_id: 22,
      visa_description: "E-1 Trader Consulate Processing",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: true,
      unpopulate_section: null,
      beneficiary_section: [15, 1, 2, 157, 249, 233, 255],
      petitioner_section: [116, 23, 6, 5, 8, 302, 301],
      caseplan_section: null,
      strategy_section: [51, 232],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-02-07T04:09:52.000Z",
      created_at: "2020-12-09T14:10:17.339Z",
    },
    {
      visa_id: 36,
      visa_code: "E-1 Trader USCIS Processing",
      configure_visa_form_id: 36,
      visa_description: "E-1 Trader USCIS Processing",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [1, 2, 15],
      petitioner_section: [8, 5, 6, 23, 116, 137, 302, 301],
      caseplan_section: null,
      strategy_section: [49, 232],
      contract_section: null,
      dependents_section: [138, 139],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-02-07T04:16:14.000Z",
      created_at: "2020-12-09T14:10:17.339Z",
    },
    {
      visa_id: 23,
      visa_code: "E-2 Employee Consulate Processing",
      configure_visa_form_id: 23,
      visa_description: "E-2 Employee Consulate Processing",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: true,
      unpopulate_section: null,
      beneficiary_section: [15, 157, 233, 249, 1, 2, 22],
      petitioner_section: [136, 116, 302, 301, 8, 5, 6, 297],
      caseplan_section: null,
      strategy_section: [50, 27, 55, 48, 44, 232, 359],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2024-04-11T19:44:38.000Z",
      created_at: "2020-12-09T14:10:17.339Z",
    },
    {
      visa_id: 70,
      visa_code: "E-2 Employee USCIS Processing",
      configure_visa_form_id: 70,
      visa_description: null,
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [15, 1, 2, 22],
      petitioner_section: [5, 6, 8, 116, 302, 301, 312],
      caseplan_section: null,
      strategy_section: [44, 27, 232],
      contract_section: null,
      dependents_section: [138, 139],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2024-04-11T20:02:23.000Z",
      created_at: "2022-07-22T18:25:42.000Z",
    },
    {
      visa_id: 69,
      visa_code: "E-2 Investor Consular Processing",
      configure_visa_form_id: 69,
      visa_description: null,
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [15, 157, 233, 249, 1, 2, 22],
      petitioner_section: [116, 302, 301, 8, 5, 6, 297, 312],
      caseplan_section: null,
      strategy_section: [55, 27, 232],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2024-04-11T20:04:19.000Z",
      created_at: "2022-07-22T18:24:10.000Z",
    },
    {
      visa_id: 24,
      visa_code: "E-2 Investor USCIS Processing",
      configure_visa_form_id: 24,
      visa_description: "E-2 Investor USCIS Processing",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: true,
      unpopulate_section: null,
      beneficiary_section: [1, 2, 15, 22],
      petitioner_section: [8, 23, 6, 5, 116, 137, 302, 301, 312, 297],
      caseplan_section: null,
      strategy_section: [48, 27, 232],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2024-04-11T20:05:27.000Z",
      created_at: "2020-12-09T14:10:17.339Z",
    },
    {
      visa_id: 4,
      visa_code: "E-3",
      configure_visa_form_id: 1,
      visa_description:
        "To Employ Foreign workers in Specialty occupations that require the theoretical",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: "30,32,36,37,38,41,47,51,52,53,54,55,56,57",
      beneficiary_section: [1, 2, 22],
      petitioner_section: [23, 8, 5, 6, 116, 137, 302, 301, 312],
      caseplan_section: null,
      strategy_section: [57],
      contract_section: null,
      dependents_section: [138, 139],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-02-07T04:19:06.000Z",
      created_at: "2020-08-10T11:43:59.000Z",
    },
    {
      visa_id: 17,
      visa_code: "EB-1-1",
      configure_visa_form_id: 17,
      visa_description: "Employment-Based Immigration First Preference EB-1",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: true,
      unpopulate_section: null,
      beneficiary_section: [58, 59, 166, 167, 168],
      petitioner_section: [115, 116, 137, 60, 302, 301, 303],
      caseplan_section: null,
      strategy_section: [28, 232],
      contract_section: null,
      dependents_section: [140, 141, 142, 143, 144],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-02-07T04:19:48.000Z",
      created_at: "2020-11-11T13:24:59.000Z",
    },
    {
      visa_id: 37,
      visa_code: "EB-1-2",
      configure_visa_form_id: 37,
      visa_description: "Employment-Based Immigration First Preference",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: true,
      unpopulate_section: null,
      beneficiary_section: [58, 59, 166, 167, 168],
      petitioner_section: [116, 137, 60, 302, 301, 303],
      caseplan_section: null,
      strategy_section: [],
      contract_section: [43, 42],
      dependents_section: [140, 143, 144],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-02-07T04:21:10.000Z",
      created_at: "2020-12-10T07:03:48.000Z",
    },
    {
      visa_id: 18,
      visa_code: "EB-1-3",
      configure_visa_form_id: 18,
      visa_description: "Employment-Based Immigration First Preference",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: true,
      unpopulate_section: null,
      beneficiary_section: [58, 59, 166, 167, 168],
      petitioner_section: [116, 137, 60, 302, 301, 303],
      caseplan_section: null,
      strategy_section: [27, 45, 232],
      contract_section: null,
      dependents_section: [140, 143, 144],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-02-07T05:04:54.000Z",
      created_at: "2020-11-11T13:25:25.000Z",
    },
    {
      visa_id: 19,
      visa_code: "EB-2 EB-3 PERM Labor Cert",
      configure_visa_form_id: 19,
      visa_description:
        "Employment-Based Immigration Second or Third Preference",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: true,
      unpopulate_section: null,
      beneficiary_section: [58, 59, 126, 166, 167, 168],
      petitioner_section: [124, 116, 137, 60, 125, 302, 301, 303],
      caseplan_section: null,
      strategy_section: [36],
      contract_section: null,
      dependents_section: [140, 143, 144],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-02-07T04:22:23.000Z",
      created_at: "2020-11-19T12:34:58.000Z",
    },
    {
      visa_id: 27,
      visa_code: "EB-2/EB-3 Schedule A Nurses",
      configure_visa_form_id: 27,
      visa_description: "EB-2/EB-3 Schedule A Nurses",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: true,
      unpopulate_section: null,
      beneficiary_section: [58, 59, 126, 166, 167, 168],
      petitioner_section: [124, 116, 137, 60, 125, 302, 301],
      caseplan_section: null,
      strategy_section: [47],
      contract_section: null,
      dependents_section: [140, 143, 144],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-02-07T04:23:04.000Z",
      created_at: "2020-12-09T14:10:17.339Z",
    },
    {
      visa_id: 25,
      visa_code: "EB-2 National Interest Waiver",
      configure_visa_form_id: 25,
      visa_description: "EB-2 National Interest Waiver",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: true,
      unpopulate_section: null,
      beneficiary_section: [58, 59, 126, 166, 167, 168],
      petitioner_section: [124, 116, 137, 60, 125, 302, 301, 303],
      caseplan_section: null,
      strategy_section: [54],
      contract_section: null,
      dependents_section: [140, 143, 144],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2021-02-01T07:18:50.000Z",
      created_at: "2020-12-09T14:10:17.339Z",
    },
    {
      visa_id: 26,
      visa_code: "EB-2 National Interest Waiver- Physicians",
      configure_visa_form_id: 26,
      visa_description: "EB-2 National Interest Waiver- Physicians",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: true,
      unpopulate_section: null,
      beneficiary_section: [58, 59, 126, 166, 167, 168],
      petitioner_section: [124, 116, 137, 60, 125, 302, 301, 303],
      caseplan_section: null,
      strategy_section: null,
      contract_section: null,
      dependents_section: [140, 143, 144],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2020-12-09T14:10:17.339Z",
    },
    {
      visa_id: 56,
      visa_code: "EB-5",
      configure_visa_form_id: 56,
      visa_description: "EB-5",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [148, 155, 166, 167, 168, 287],
      petitioner_section: [149, 152, 153, 116, 137, 154, 151, 302, 301, 303],
      caseplan_section: null,
      strategy_section: [27, 156, 232],
      contract_section: null,
      dependents_section: [318, 157, 144, 143, 140, 141, 138, 139],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2024-02-22T23:13:56.000Z",
      created_at: "2021-06-26T16:12:43.000Z",
    },
    {
      visa_id: 28,
      visa_code: "EB-5 Regional Center Project",
      configure_visa_form_id: 28,
      visa_description: "EB-5 Regional Center Project",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: true,
      unpopulate_section: null,
      beneficiary_section: [155, 148, 166, 167, 168, 314],
      petitioner_section: [
        116, 137, 154, 151, 149, 152, 153, 300, 302, 301, 303, 313,
      ],
      caseplan_section: null,
      strategy_section: [27, 261],
      contract_section: null,
      dependents_section: [
        140, 141, 134, 133, 131, 130, 143, 144, 318, 138, 139,
      ],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2024-02-22T23:12:59.000Z",
      created_at: "2020-12-09T14:10:17.339Z",
    },
    {
      visa_id: 84,
      visa_code: "EB-5 Removal of Conditions",
      configure_visa_form_id: 84,
      visa_description: null,
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [148, 155, 166, 168, 167],
      petitioner_section: [152, 153, 116, 154, 137, 151, 149, 302, 301, 303],
      caseplan_section: null,
      strategy_section: [27, 156],
      contract_section: null,
      dependents_section: [318, 157, 144, 141, 140, 138, 139],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2024-02-22T23:19:39.000Z",
      created_at: "2023-04-13T13:07:28.000Z",
    },
    {
      visa_id: 29,
      visa_code: "EB-5 Stand Alone Project",
      configure_visa_form_id: 29,
      visa_description: "EB-5 Stand Alone Project",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: true,
      unpopulate_section: null,
      beneficiary_section: [166, 148, 155, 168, 167],
      petitioner_section: [116, 137, 152, 153, 154, 151, 149, 302, 301, 303],
      caseplan_section: [338],
      strategy_section: [27, 156],
      contract_section: null,
      dependents_section: [138, 139, 144, 140, 318, 157],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2024-02-22T23:12:19.000Z",
      created_at: "2020-12-09T14:10:17.339Z",
    },
    {
      visa_id: 57,
      visa_code: "F-1 Student Visa",
      configure_visa_form_id: 57,
      visa_description: "F-1 Student Visa",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [157],
      petitioner_section: [302, 301],
      caseplan_section: null,
      strategy_section: null,
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2021-08-02T14:28:09.000Z",
      created_at: "2021-08-02T09:38:39.000Z",
    },
    {
      visa_id: 44,
      visa_code: "Family Based - Adjustment of Status ONLY ",
      configure_visa_form_id: 44,
      visa_description:
        "Adjustment of Status following approval of I-130 (inside the U.S.)",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [166, 167, 168, 299],
      petitioner_section: [116],
      caseplan_section: null,
      strategy_section: [39],
      contract_section: null,
      dependents_section: [144, 143, 140, 318],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-12-21T14:28:17.000Z",
      created_at: "2020-12-10T07:09:08.000Z",
    },
    {
      visa_id: 88,
      visa_code: "Family Based - Child of Permanent Resident (F2A)",
      configure_visa_form_id: 88,
      visa_description: "Children of Permanent Residents",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [299, 218, 333],
      petitioner_section: [116, 220, 335],
      caseplan_section: null,
      strategy_section: [306],
      contract_section: null,
      dependents_section: [318],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-12-25T07:21:54.000Z",
      created_at: "2023-06-14T19:36:13.000Z",
    },
    {
      visa_id: 89,
      visa_code: "Family Based - Child of Permanent Residents (F2B)",
      configure_visa_form_id: 89,
      visa_description:
        "Unmarried Sons and Daughters (21 years of age or older) of Permanent Residents",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [299, 218, 333],
      petitioner_section: [116, 220, 335],
      caseplan_section: null,
      strategy_section: [311],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-09-10T17:54:48.000Z",
      created_at: "2023-06-14T19:37:20.000Z",
    },
    {
      visa_id: 92,
      visa_code: "Family Based - Child of U.S. Citizen (F1)",
      configure_visa_form_id: 92,
      visa_description:
        "Unmarried Sons and Daughters of U.S. Citizens (Family Based)",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [299, 218, 333],
      petitioner_section: [116, 220, 335],
      caseplan_section: null,
      strategy_section: [307],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-09-10T17:55:24.000Z",
      created_at: "2023-06-14T19:38:42.000Z",
    },
    {
      visa_id: 41,
      visa_code: "FAMILY-BASED – Immediate Relative of U.S. Citizen",
      configure_visa_form_id: 41,
      visa_description: "FAMILY-BASED – Immediate Relative of U.S. Citizen",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [166, 167, 168, 285, 218, 270, 299],
      petitioner_section: [116, 286, 302, 301, 220],
      caseplan_section: null,
      strategy_section: [39],
      contract_section: null,
      dependents_section: [144, 140, 143],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-09-10T17:57:07.000Z",
      created_at: "2020-12-10T07:07:35.000Z",
    },
    {
      visa_id: 90,
      visa_code: "Family Based - Married Child of U.S. Citizen (F3)",
      configure_visa_form_id: 90,
      visa_description: "Married Sons and Daughters of U.S. Citizens ",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [299, 218, 333],
      petitioner_section: [116, 220, 335],
      caseplan_section: null,
      strategy_section: [308],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-09-10T17:57:59.000Z",
      created_at: "2023-06-14T19:37:46.000Z",
    },
    {
      visa_id: 93,
      visa_code: "Family Based - Parent of U.S. Citizen - Adjustment of Status",
      configure_visa_form_id: 93,
      visa_description:
        "Family based permanent residency visa (Greencard) for parents of US citizens over 21 years of age. ",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [299, 270, 166, 168, 285, 218, 167, 333],
      petitioner_section: [116, 275, 220, 286],
      caseplan_section: null,
      strategy_section: [315],
      contract_section: null,
      dependents_section: [318],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-12-21T14:32:31.000Z",
      created_at: "2023-07-22T17:41:27.000Z",
    },
    {
      visa_id: 91,
      visa_code: "Family Based - Sibling of U.S. Citizen (F4)",
      configure_visa_form_id: 91,
      visa_description: "Brothers and Sisters of Adult U.S. Citizens",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [299, 218, 333],
      petitioner_section: [116, 220, 335],
      caseplan_section: null,
      strategy_section: [309],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-09-10T17:58:46.000Z",
      created_at: "2023-06-14T19:38:11.000Z",
    },
    {
      visa_id: 87,
      visa_code: "Family Based - Spouse of Permanent Resident (F2A)",
      configure_visa_form_id: 87,
      visa_description: "Immigrant Petition for Spouse of Permanent Resident ",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [270, 218, 299, 333],
      petitioner_section: [275, 220, 116, 335],
      caseplan_section: null,
      strategy_section: [304],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-09-10T18:00:31.000Z",
      created_at: "2023-06-13T22:39:35.000Z",
    },
    {
      visa_id: 39,
      visa_code:
        "Family Based - Spouse of U.S. Citizen - Adjustment of Status ",
      configure_visa_form_id: 39,
      visa_description:
        "Adjustment of Status for Spouse of U.S. Citizen (inside the U.S.)",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [270, 166, 168, 167, 285, 299, 218, 333],
      petitioner_section: [116, 220, 228, 286, 302, 301, 275],
      caseplan_section: null,
      strategy_section: [39],
      contract_section: null,
      dependents_section: [318],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-12-14T15:49:35.000Z",
      created_at: "2020-12-10T07:07:06.000Z",
    },
    {
      visa_id: 40,
      visa_code: "Family Based - Spouse of U.S. Citizen - Consular Processing",
      configure_visa_form_id: 40,
      visa_description:
        "Marriage to spouse of U.S. citizen outside of the United States (Consular Processing)",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [270, 218, 299, 305],
      petitioner_section: [116, 220, 275, 286],
      caseplan_section: null,
      strategy_section: [39],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-09-10T18:57:40.000Z",
      created_at: "2020-12-10T07:07:21.000Z",
    },
    {
      visa_id: 1,
      visa_code: "H-1B",
      configure_visa_form_id: 1,
      visa_description:
        "To Employ foreign national workers in specialty occupations.",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: "30,32,36,37,38,41,47,51,52,53,54,55,56,57",
      beneficiary_section: [1, 2, 3, 22, 114],
      petitioner_section: [
        8, 5, 6, 20, 23, 40, 116, 137, 21, 228, 302, 301, 312, 211,
      ],
      caseplan_section: null,
      strategy_section: [7],
      contract_section: [43, 42],
      dependents_section: [138, 139, 144],
      lca_section: [122],
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2024-04-18T12:22:57.000Z",
      created_at: "2020-01-08T22:51:37.000Z",
    },
    {
      visa_id: 3,
      visa_code: "H-1B1",
      configure_visa_form_id: 1,
      visa_description:
        "To employ foreign national workers from Chile and Singapore in specialty occupations. ",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: "30,32,36,37,38,41,47,51,52,53,54,55,56,57",
      beneficiary_section: null,
      petitioner_section: [116, 137, 302, 301, 312],
      caseplan_section: null,
      strategy_section: [7],
      contract_section: null,
      dependents_section: [138, 139],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-02-07T04:53:55.000Z",
      created_at: "2020-08-10T11:43:35.000Z",
    },
    {
      visa_id: 16,
      visa_code: "H-1B 3rd Party Placement",
      configure_visa_form_id: 16,
      visa_description: "H-1B for Thrid Party Placement",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: "30,32,36,37,38,41,47,51,52,53,54,55,56,57",
      beneficiary_section: [1, 2, 3],
      petitioner_section: [8, 5, 6, 23, 116, 137, 302, 301, 312],
      caseplan_section: null,
      strategy_section: [29],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2020-10-26T16:17:40.000Z",
    },
    {
      visa_id: 42,
      visa_code: "H-2A",
      configure_visa_form_id: 42,
      visa_description: "Temporary or Seasonal Agricultural Workers",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [258],
      petitioner_section: [116, 137, 302, 301],
      caseplan_section: null,
      strategy_section: null,
      contract_section: null,
      dependents_section: [138, 139],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-02-07T04:54:54.000Z",
      created_at: "2020-12-10T07:08:04.000Z",
    },
    {
      visa_id: 48,
      visa_code: "H-2B",
      configure_visa_form_id: 48,
      visa_description: "Temporary Non-Agricultural Worker",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [1, 22, 3, 16, 2, 215],
      petitioner_section: [
        116, 23, 41, 6, 5, 8, 137, 13, 211, 213, 124, 302, 301, 329, 330, 331,
        332,
      ],
      caseplan_section: null,
      strategy_section: [37],
      contract_section: null,
      dependents_section: [138],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-11-17T12:22:17.000Z",
      created_at: "2021-02-23T00:09:25.000Z",
    },
    {
      visa_id: 43,
      visa_code: "H-3",
      configure_visa_form_id: 43,
      visa_description:
        "Nonimmigrant Trainee or Special Education Exchange Visitor",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: null,
      petitioner_section: [116, 137, 302, 301],
      caseplan_section: null,
      strategy_section: null,
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-02-07T04:56:35.000Z",
      created_at: "2020-12-10T07:08:34.000Z",
    },
    {
      visa_id: 99,
      visa_code: "H-4",
      configure_visa_form_id: 99,
      visa_description: null,
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [168],
      petitioner_section: null,
      caseplan_section: null,
      strategy_section: null,
      contract_section: null,
      dependents_section: [144, 138, 139],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2023-12-19T17:28:29.000Z",
    },
    {
      visa_id: 54,
      visa_code: "International Entrepreneur Parole",
      configure_visa_form_id: 54,
      visa_description: "International Entrepreneur Parole",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [146],
      petitioner_section: [302, 301],
      caseplan_section: null,
      strategy_section: [145, 232],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2021-06-18T09:03:01.000Z",
      created_at: "2021-06-04T13:40:17.000Z",
    },
    {
      visa_id: 72,
      visa_code: "Investigations",
      configure_visa_form_id: 72,
      visa_description: null,
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: null,
      petitioner_section: [302, 301],
      caseplan_section: null,
      strategy_section: [208],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2022-11-10T19:46:55.000Z",
    },
    {
      visa_id: 59,
      visa_code: "J-1 Trainee",
      configure_visa_form_id: 59,
      visa_description:
        "Training programs are designed to allow foreign professionals to come to the United States to gain exposure to U.S. culture and to receive training in U.S. business practices in their chosen occupational field.",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [170],
      petitioner_section: [165, 137, 116, 302, 301],
      caseplan_section: null,
      strategy_section: [169],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2021-10-08T10:58:45.000Z",
    },
    {
      visa_id: 50,
      visa_code: "J-1 Visa (Intern)",
      configure_visa_form_id: 50,
      visa_description: "J-1 for interns",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [170],
      petitioner_section: [116, 137, 165, 302, 301],
      caseplan_section: null,
      strategy_section: [169],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2021-02-23T11:07:55.000Z",
    },
    {
      visa_id: 49,
      visa_code: "J-1 Visa (Management Trainee)",
      configure_visa_form_id: 49,
      visa_description: "J-1",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [170],
      petitioner_section: [116, 137, 165, 302, 301],
      caseplan_section: null,
      strategy_section: [169],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2021-02-23T11:07:10.000Z",
      created_at: "2021-02-23T11:06:11.000Z",
    },
    {
      visa_id: 101,
      visa_code: "J-1 Waiver",
      configure_visa_form_id: 101,
      visa_description:
        "If an individual is required to return home due to the 2 year home residency requirement, they may request a waiver. ",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: null,
      petitioner_section: null,
      caseplan_section: null,
      strategy_section: null,
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2024-02-14T17:50:41.000Z",
    },
    {
      visa_id: 30,
      visa_code: "K-1 Fiance",
      configure_visa_form_id: 30,
      visa_description: "K-1 Fiance",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [296, 294],
      petitioner_section: [116, 137, 297, 295, 302, 301],
      caseplan_section: null,
      strategy_section: null,
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-06-02T13:10:33.000Z",
      created_at: "2020-12-09T14:10:17.339Z",
    },
    {
      visa_id: 12,
      visa_code: "L-1A Visa",
      configure_visa_form_id: 12,
      visa_description: "Multinational Executive or Manager",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: true,
      unpopulate_section: "30,32,36,37,38,41,51,52,53,54,55,56,57",
      beneficiary_section: [1, 2, 14],
      petitioner_section: [8, 5, 6, 116, 137, 302, 301, 312],
      caseplan_section: null,
      strategy_section: [26, 27, 232],
      contract_section: [43, 42],
      dependents_section: [138, 139],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-02-07T04:59:13.000Z",
      created_at: "2020-10-19T08:24:07.000Z",
    },
    {
      visa_id: 15,
      visa_code: "L-1B Visa",
      configure_visa_form_id: 15,
      visa_description: "Intracompany Transferee Specialized Knowledge",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: true,
      unpopulate_section: "30,32,36,37,38,41,51,52,53,54,55,56,57",
      beneficiary_section: [1, 2, 14],
      petitioner_section: [8, 5, 6, 116, 137, 302, 301, 312],
      caseplan_section: null,
      strategy_section: [32, 27, 232],
      contract_section: null,
      dependents_section: [138, 139],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-02-07T05:00:39.000Z",
      created_at: "2020-10-19T08:32:21.000Z",
    },
    {
      visa_id: 76,
      visa_code: "Lease Agreement",
      configure_visa_form_id: 76,
      visa_description:
        "Lease Agreements are used in numerous visas including but not limited to H-1B, IEP, E-1, E-2, EB-1-1, EB-1-3, EB-5, L-1, O-1 and others",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: null,
      petitioner_section: [302, 301],
      caseplan_section: null,
      strategy_section: [232],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-03-07T13:07:28.000Z",
      created_at: "2023-03-05T16:25:34.000Z",
    },
    {
      visa_id: 73,
      visa_code: "Litigation",
      configure_visa_form_id: 73,
      visa_description: null,
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: null,
      petitioner_section: [302, 301, 136],
      caseplan_section: null,
      strategy_section: [209],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2022-12-01T19:29:53.000Z",
    },
    {
      visa_id: 45,
      visa_code: "N-400",
      configure_visa_form_id: 45,
      visa_description: "NATURALIZATION",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: null,
      petitioner_section: [116],
      caseplan_section: null,
      strategy_section: null,
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2020-12-10T07:09:35.000Z",
    },
    {
      visa_id: 52,
      visa_code: "National Interest Exemption",
      configure_visa_form_id: 52,
      visa_description: "NIE waiver from US Consulates to enter United States",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: null,
      petitioner_section: [116, 302, 301],
      caseplan_section: null,
      strategy_section: [118],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2021-02-26T22:10:43.000Z",
    },

    {
      visa_id: 13,
      visa_code: "O-1A Visa",
      configure_visa_form_id: 13,
      visa_description: "Extraordinary Ability",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: true,
      unpopulate_section: "30,32,36,37,38,41,47,52,53,54,55,56,57",
      beneficiary_section: [1, 2],
      petitioner_section: [8, 5, 6, 23, 116, 137, 25, 302, 301, 312],
      caseplan_section: null,
      strategy_section: [24, 27, 232],
      contract_section: [43, 42],
      dependents_section: [138],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2020-10-23T14:50:55.000Z",
      created_at: "2020-10-19T08:25:06.000Z",
    },
    {
      visa_id: 14,
      visa_code: "O-1B Visa",
      configure_visa_form_id: 14,
      visa_description: "Extraordinary Ability",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: true,
      unpopulate_section: "30,32,36,37,38,41,47,52,53,54,55,56,57",
      beneficiary_section: [1, 2],
      petitioner_section: [8, 5, 6, 116, 137, 25, 312],
      caseplan_section: null,
      strategy_section: [19],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2020-10-23T14:51:57.000Z",
      created_at: "2020-10-19T08:25:24.000Z",
    },
    {
      visa_id: 71,
      visa_code: "O-2",
      configure_visa_form_id: 71,
      visa_description:
        "If the O-2 beneficiary is accompanying an O-1 beneficiary in the television or motion picture industry, he or she must have skills and experience with the O-1 beneficiary that are not of a general nature and skills that are critical, due to a pre-existing or long-standing working relationship with the O-1 beneficiary. If he or she is accompanying the O-1 beneficiary for a specific production only, the person may be eligible for an O-2 classification because significant production (including pre- and post-production work) will take place both inside and outside the United States and the continuing participation of the O-2 beneficiary is essential to the successful completion of the production.",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: null,
      petitioner_section: [302, 301, 25],
      caseplan_section: null,
      strategy_section: null,
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2022-09-21T18:04:00.000Z",
    },
    {
      visa_id: 100,
      visa_code: "O-2 Visa",
      configure_visa_form_id: 100,
      visa_description: "Visa for Supporting Personnel of O1 Visa Holders",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: null,
      petitioner_section: null,
      caseplan_section: null,
      strategy_section: null,
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2024-02-05T15:43:54.000Z",
    },
    {
      visa_id: 5,
      visa_code: "P-1",
      configure_visa_form_id: 5,
      visa_description: "Athlete Entertainer Visa",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: true,
      unpopulate_section: "30,32,36,37,38,41,43,47,52,53,54,55,56,57",
      beneficiary_section: [1, 2, 334],
      petitioner_section: [8, 5, 6, 23, 116, 137, 25, 302, 301, 312],
      caseplan_section: null,
      strategy_section: [30],
      contract_section: [43, 42],
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2024-01-23T19:45:37.000Z",
      created_at: "2020-09-02T16:03:47.000Z",
    },
    {
      visa_id: 31,
      visa_code: "P-1 Major League Baseball",
      configure_visa_form_id: 31,
      visa_description: "P-1 Major League Baseball",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: true,
      unpopulate_section: null,
      beneficiary_section: [1, 2],
      petitioner_section: [8, 5, 6, 116, 137, 25, 302, 301, 312],
      caseplan_section: null,
      strategy_section: [33, 34],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-09-11T01:19:22.000Z",
      created_at: "2020-12-09T14:10:17.339Z",
    },
    {
      visa_id: 58,
      visa_code: "P-1 Major League Rugby",
      configure_visa_form_id: 58,
      visa_description: "P-1 visa for professional rugby players",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [1, 2],
      petitioner_section: [5, 6, 8, 23, 25, 116, 137, 302, 301, 312],
      caseplan_section: null,
      strategy_section: [164],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2021-08-30T09:58:26.000Z",
      created_at: "2021-08-30T09:53:35.000Z",
    },
    {
      visa_id: 32,
      visa_code: "P-1S",
      configure_visa_form_id: 32,
      visa_description: "Essential Support Personnel for P-1",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: true,
      unpopulate_section: null,
      beneficiary_section: [1, 2],
      petitioner_section: [6, 8, 5, 116, 137, 25, 302, 301, 312],
      caseplan_section: null,
      strategy_section: null,
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2020-12-09T14:10:17.339Z",
    },
    {
      visa_id: 33,
      visa_code: "P-1S Major League Baseball",
      configure_visa_form_id: 33,
      visa_description: "P-1S Major League Sports",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: true,
      unpopulate_section: null,
      beneficiary_section: [1, 2],
      petitioner_section: [8, 5, 6, 116, 137, 25, 312],
      caseplan_section: null,
      strategy_section: null,
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-09-11T01:14:42.000Z",
      created_at: "2020-12-09T14:10:17.339Z",
    },
    {
      visa_id: 86,
      visa_code: "P-3 Culturally Unique Artist",
      configure_visa_form_id: 86,
      visa_description: "Visa for culturally unique artist",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [1, 2],
      petitioner_section: [5, 6, 8, 23, 25, 116, 137, 302, 301, 312],
      caseplan_section: null,
      strategy_section: [289],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2023-05-23T21:02:38.000Z",
    },
    {
      visa_id: 34,
      visa_code: "R-1",
      configure_visa_form_id: 34,
      visa_description: "R-1",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [1, 2],
      petitioner_section: [116, 137, 40, 8, 6, 5, 302, 301],
      caseplan_section: null,
      strategy_section: null,
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2020-12-09T14:10:17.339Z",
    },
    {
      visa_id: 65,
      visa_code: "Refugee Adjustment of Status",
      configure_visa_form_id: 65,
      visa_description:
        "This process is to adjust refugees to permanent residency",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [166, 168, 167],
      petitioner_section: [116, 302, 301],
      caseplan_section: null,
      strategy_section: [173],
      contract_section: null,
      dependents_section: [318],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-12-21T14:33:00.000Z",
      created_at: "2022-04-19T11:54:17.000Z",
    },
    {
      visa_id: 64,
      visa_code: "Refugee Derivative Family Member Following Along",
      configure_visa_form_id: 64,
      visa_description:
        "This visa is for certain immediate family members following those who have been granted refugee status",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: null,
      petitioner_section: [302, 301],
      caseplan_section: null,
      strategy_section: null,
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2022-04-19T11:53:13.000Z",
    },
    {
      visa_id: 81,
      visa_code: "Removal of Conditions",
      configure_visa_form_id: 81,
      visa_description: "Removal of Conditions for Family-Based Visa",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [265, 299],
      petitioner_section: [280, 302, 301, 116],
      caseplan_section: null,
      strategy_section: [263],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-09-26T13:49:10.000Z",
      created_at: "2023-04-03T18:32:37.000Z",
    },
    {
      visa_id: 82,
      visa_code: "Removal of Conditions: I-90",
      configure_visa_form_id: 82,
      visa_description: "Removal of Conditions I-90 Application",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: null,
      petitioner_section: [302, 301],
      caseplan_section: null,
      strategy_section: null,
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2023-04-04T20:22:49.000Z",
    },
    {
      visa_id: 53,
      visa_code: "Renewal of Greencard (I-90)",
      configure_visa_form_id: 53,
      visa_description: "Filing I-90 to replace expired or lost Greencard ",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: null,
      petitioner_section: [116, 137, 302, 301],
      caseplan_section: null,
      strategy_section: [120],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2021-03-01T22:57:55.000Z",
    },
    {
      visa_id: 83,
      visa_code: "Responder",
      configure_visa_form_id: 83,
      visa_description:
        "For drafting responses to RFEs, NOIDs, NOIRs, MTRs or Appeals",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: null,
      petitioner_section: [302, 301],
      caseplan_section: null,
      strategy_section: [266],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2023-04-08T17:03:20.000Z",
    },
    {
      visa_id: 98,
      visa_code: "Retainer ",
      configure_visa_form_id: 98,
      visa_description: null,
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: null,
      petitioner_section: null,
      caseplan_section: null,
      strategy_section: null,
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2023-10-02T21:18:56.000Z",
    },
    {
      visa_id: 47,
      visa_code: "Strategy Session",
      configure_visa_form_id: 47,
      visa_description: "This is to be used when we do a strategy session",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: null,
      petitioner_section: [302, 301],
      caseplan_section: null,
      strategy_section: null,
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2021-01-21T03:21:56.000Z",
    },
    {
      visa_id: 63,
      visa_code: "Temporary Protected Status",
      configure_visa_form_id: 63,
      visa_description:
        "This status is for nationals of countries that have been granted Temporary Protected Status by the USA,",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [168],
      petitioner_section: [302, 301],
      caseplan_section: null,
      strategy_section: [175],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2022-04-19T11:42:39.000Z",
    },
    {
      visa_id: 35,
      visa_code: "TN ",
      configure_visa_form_id: 35,
      visa_description:
        "The TN nonimmigrant classification permits qualified Canadian and Mexican citizens to seek temporary",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [1, 2],
      petitioner_section: [116, 137, 20, 6, 5, 8, 23, 302, 301, 312],
      caseplan_section: null,
      strategy_section: [38],
      contract_section: null,
      dependents_section: [138, 139],
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2022-08-23T15:46:54.000Z",
      created_at: "2020-12-09T14:10:17.339Z",
    },
    {
      visa_id: 68,
      visa_code: "Uniting Ukraine Parole",
      configure_visa_form_id: 68,
      visa_description:
        "Uniting for Ukraine provides a pathway for Ukrainian citizens and their immediate family members who are outside the United States to come to the United States and stay temporarily in a two-year period of parole. Ukrainians participating in Uniting for Ukraine must have a supporter in the United States who agrees to provide them with financial support for the duration of their stay in the United States.",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: null,
      petitioner_section: [302, 301],
      caseplan_section: null,
      strategy_section: [195],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2022-05-12T00:14:31.000Z",
    },
    {
      visa_id: 75,
      visa_code: "U Visa",
      configure_visa_form_id: 75,
      visa_description:
        "The U nonimmigrant status (U visa) is set aside for victims of certain crimes who have suffered mental or physical abuse and are helpful to law enforcement or government officials in the investigation or prosecution of criminal activity. ",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: [231],
      petitioner_section: [116, 302, 301],
      caseplan_section: null,
      strategy_section: [230],
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: "2023-03-13T18:14:59.000Z",
      created_at: "2023-03-04T17:58:12.000Z",
    },
    {
      visa_id: 74,
      visa_code: "U Visa",
      configure_visa_form_id: 74,
      visa_description:
        "The U nonimmigrant status (U visa) is set aside for victims of certain crimes who have suffered mental or physical abuse and are helpful to law enforcement or government officials in the investigation or prosecution of criminal activity. ",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: null,
      petitioner_section: [302, 301],
      caseplan_section: null,
      strategy_section: null,
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2023-03-04T17:57:51.000Z",
    },
    {
      visa_id: 102,
      visa_code: "Not Sure Yet",
      configure_visa_form_id: 102,
      visa_description: "Not Sure Yet",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: null,
      petitioner_section: null,
      caseplan_section: null,
      strategy_section: null,
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2024-04-25T12:16:04.000Z",
    },
    {
      visa_id: 103,
      visa_code: "Multiple Options",
      configure_visa_form_id: 103,
      visa_description: "Multiple Options",
      beneficiary_steps: null,
      petitioner_steps: null,
      country: "USA",
      status: "active",
      petitioner_show_beneficiary: false,
      unpopulate_section: null,
      beneficiary_section: null,
      petitioner_section: null,
      caseplan_section: null,
      strategy_section: null,
      contract_section: null,
      dependents_section: null,
      lca_section: null,
      dependent_as_normal_section: null,
      additional_document_template: null,
      modified_at: null,
      created_at: "2024-04-25T12:16:20.000Z",
    },
  ],
};

export const processingTypeList = [
  {
    id: 1,
    processing_type: "Consular Processing",
    status: "true",
  },
  {
    id: 2,
    processing_type: "Change of Status",
    status: "true",
  },
];

export const processingFiling = [
  {
    id: 1,
    filing_type: "Cap-Based",
    status: "true",
  },
  {
    id: 2,
    filing_type: "Cap-Exempt",
    status: "true",
  },
  {
    id: 3,
    filing_type: "Transfer",
    status: "true",
  },
  {
    id: 4,
    filing_type: "First Exension",
    status: "true",
  },
  {
    id: 5,
    filing_type: "Extension Beyond the First",
    status: "true",
  },
  {
    id: 6,
    filing_type: "Amendment without extension",
    status: "true",
  },
];
