import React from "react";
import visasAi from "../../assets/visasai.svg";
import chatInfo from "../../assets/chat-info.svg";
import "./index.scss";
import ovieAI from "../../assets/ovie-universal.png";
interface Props {
  chatTitle?: string;
  children?: any;
  className?: string;
}
export const Header: React.FC<Props> = (pops) => {
  return (
    <div
      className={`header_container ${pops.className ? pops.className : ""} `}
    >
      <div className="logo_head">
        <span className="poweredby"> Powered by </span>{" "}
        <img src={visasAi} alt="visas.ai" />
      </div>
      {pops.chatTitle ? (
        <div className="chat_info">
          <div className="fl-1">
            <img className="chat_avatar" src={ovieAI} alt="AI" />
          </div>
          <div className="fl-2 position-re">
            <img className="img_chat_layer" src={chatInfo} alt="" />
            <div
              className="chat-text"
              dangerouslySetInnerHTML={{
                __html: pops?.chatTitle,
              }}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
