import React, { useCallback, useContext, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { getFirmDetails } from "../../utils/store";
import { MyContext } from "../../MyContext";

export const ContainerLayout = () => {
  const param = useParams();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const { setFirmUnique, setFirmDetails } = useContext<any>(MyContext);

  const firmDetails = useCallback(
    async (id: string) => {
      try {
        setLoader(true);
        setFirmUnique(id);
        localStorage.setItem("firmId", id as any);
        const result = await getFirmDetails(id as any);
        setLoader(false);
        setFirmDetails(result);
        navigate("/account");
      } catch (error) {}
    },
    [navigate, setFirmDetails, setFirmUnique]
  );
  useEffect(() => {
    const FirmIds = localStorage.getItem("firmId");
    if (param?.id || FirmIds) {
      firmDetails(param?.id ? (param?.id as any) : FirmIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   window.history.pushState(null, document.title, window.location.href);
  //   window.addEventListener("popstate", function (event) {
  //     window.history.pushState(null, document.title, window.location.href);
  //   });
  // });

  if (loader) {
    return <div className="liberty-loader" />;
  }
  return (
    <React.Fragment>
      <Outlet />
    </React.Fragment>
  );
};
