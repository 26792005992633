import * as Yup from "yup";

export const petitionerFormSchema = Yup.object().shape({
  orgnization: Yup.string()
    .nullable()
    .trim()
    .required("Organization Name is required"),
  firstName: Yup.string().nullable().trim().required("First Name is required"),
  lastName: Yup.string().nullable().trim("Enter a valid last name").optional(),
  email: Yup.string()
    .required("Email is required")
    .email("Email must be a valid email address"),
  phone: Yup.string().nullable().trim().optional(),
  phoneObj: Yup.object()
    .shape({
      name: Yup.string().nullable().optional(),
      dialCode: Yup.string().nullable().optional(),
      countryCode: Yup.string().nullable().optional(),
      format: Yup.string().nullable().optional(),
    })
    .nullable()
    .optional(),
});

export const myselfFormSchema = Yup.object().shape({
  firstName: Yup.string().nullable().trim().required("First Name is required"),
  lastName: Yup.string().nullable().trim("Enter a valid last name").optional(),
  email: Yup.string()
    .required("Email is required")
    .email("Email must be a valid email address"),
  phone: Yup.string().nullable().trim().optional(),
  phoneObj: Yup.object()
    .shape({
      name: Yup.string().nullable().optional(),
      dialCode: Yup.string().nullable().optional(),
      countryCode: Yup.string().nullable().optional(),
      format: Yup.string().nullable().optional(),
    })
    .nullable()
    .optional(),
});

export const visaTypeSchema = Yup.object().shape({
  visaType: Yup.string().nullable().trim().optional(),
  notes: Yup.string().nullable().trim("Enter a valid notes").optional(),
});

export const premiumProcessingSchema = Yup.object().shape({
  premiumProcessing: Yup.string().nullable().optional(),
  processingType: Yup.string().nullable().optional(),
  totalEmployeesInCompany: Yup.string().nullable().optional(),
  numberOfDependents: Yup.string().nullable().optional(),
  fillingType: Yup.string().nullable().optional(),
});
