import React from "react";
import { useNavigate } from "react-router-dom";
import "./index.scss";

interface Props {
  submit: () => void;
  submitbtn?: boolean;
}
export const Footer: React.FC<Props> = (props: Props) => {
  const { submit, submitbtn } = props;
  const navigate = useNavigate();
  return (
    <div className="bottom_container">
      <button type="submit" className="btn_color" onClick={() => navigate(-1)}>
        Prev
      </button>
      <button
        type="submit"
        className="btn_color"
        onClick={() => submit && submit()}
      >
        {submitbtn ? "Submit" : "Next"}
      </button>
    </div>
  );
};
